type ApiResponse = {
  token: string
}

const API_REQUEST_PATH = '/appsflyer/auth-token'

export async function appsflyerAuthTokenGet() {
  const response = await useApiApp<ApiResponse>(API_REQUEST_PATH)
  return response.data?.token || ''
}
