import { LINK_TO_DOWNLOAD_APP } from '@/shared/config'

import { appsflyerAuthTokenGet } from '../api/appsflyerAuthTokenGet.api'

const token = ref('')

export function useDeeplink() {
  const { isDesktop } = useLayout()
  const $modal = useModal()

  onMounted(async () => {
    if (token.value) return
    token.value = await appsflyerAuthTokenGet()
  })

  function reset() {
    token.value = ''
  }

  function open() {
    if (isDesktop.value) $modal.open('download-app')
    else {
      navigateTo(createLink(), { external: true, open: { target: '_self' } })
    }
  }

  function createLink() {
    return LINK_TO_DOWNLOAD_APP + '&deep_link_value=' + token.value
  }

  return {
    open,
    reset,
    createLink,
  }
}
